:root {
    --primaryColor: hsl(94, 59%, 35%);
    --HoverVolor: #bdf094;
    --paleGreen: hsl(96, 75%, 89%);
    --whiteGreen: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0, 18%);
    --textColor: hsl(240, 1%, 48%);
    --bgColor: hsl( 220, 10%, 94% );
    --greyText: rgba(190, 190, 190);
    --inputColor: hsl(330, 12%, 97); 
    --itemcardcolor: hsl(120, 5%, 88%);
    --itemcardhover: hsl(120, 37%, 87%);

    --BoxShadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;;

    /* font type */
  
    --biggesFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontsize: .948rem;
    --smalllFontsize: .813rem;
    --smallestFontsize: .75rem;
  
  
}

.activity-section{
    flex-basis: 50%;


    .heading {
        width: 100%;
        justify-content: space-between;
        

        h1 {
            font-size: var(--h2FontSize);
            color: var(--blackColor);
            font-weight: 700;
        }

        .btn {
            border: 1px solid transparent;

            &:hover {
                border: 1px solid var(--primaryColor);

            }
        }

     
    }
  .section-container {
      margin-top: 1rem;

    .single-custumer {
        
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
        

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
            box-shadow: var(--BoxShadow);
        }
    
        .custumer-detail {
            flex: 1;
            
            flex-direction: column;
            align-items: flex-start;

            .name {
                font-size: var(--h3FontSize);
                font-weight: 600;
                color: var(--blackColor);

            }
            .small {
                font-size: var(--smalllFontsize);
                font-weight: 500;
                color: var(--textColor);



            }

        }

        .duration {

        }
    }
  }


}