:root {
  --primaryColor: hsl(94, 59%, 35%);
  --HoverVolor: #bdf094;
  --paleGreen: hsl(96, 75%, 89%);
  --whiteGreen: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0, 18%);
  --textColor: hsl(240, 1%, 48%);
  --bgColor: hsl( 220, 10%, 94% );
  --greyText: rgba(190, 190, 190);
  --inputColor: hsl(330, 12%, 97);
  --itemcardcolor: hsl(120, 5%, 88%);
  --itemcardhover: hsl(120, 37%, 87%);
  --BoxShadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* font type */
  --biggesFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontsize: .948rem;
  --smalllFontsize: .813rem;
  --smallestFontsize: .75rem;
}

.activity-section {
  flex-basis: 50%;
}
.activity-section .heading {
  width: 100%;
  justify-content: space-between;
}
.activity-section .heading h1 {
  font-size: var(--h2FontSize);
  color: var(--blackColor);
  font-weight: 700;
}
.activity-section .heading .btn {
  border: 1px solid transparent;
}
.activity-section .heading .btn:hover {
  border: 1px solid var(--primaryColor);
}
.activity-section .section-container {
  margin-top: 1rem;
}
.activity-section .section-container .single-custumer {
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
}
.activity-section .section-container .single-custumer img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  box-shadow: var(--BoxShadow);
}
.activity-section .section-container .single-custumer .custumer-detail {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.activity-section .section-container .single-custumer .custumer-detail .name {
  font-size: var(--h3FontSize);
  font-weight: 600;
  color: var(--blackColor);
}
.activity-section .section-container .single-custumer .custumer-detail .small {
  font-size: var(--smalllFontsize);
  font-weight: 500;
  color: var(--textColor);
}/*# sourceMappingURL=acvity.css.map */