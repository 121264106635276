:root {
    --primaryColor: hsl(94, 59%, 35%);
    --HoverVolor: #bdf094;
    --paleGreen: hsl(96, 75%, 89%);
    --whiteGreen: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0, 18%);
    --textColor: hsl(240, 1%, 48%);
    --bgColor: hsl( 220, 10%, 94% );
    --greyText: rgba(190, 190, 190);
    --inputColor: hsl(330, 12%, 97); 
    --itemcardcolor: hsl(120, 5%, 88%);
    --itemcardhover: hsl(120, 37%, 87%);

    --BoxShadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;;

    /* font type */
  
    --biggesFontSize: 2.5rem;
    --h1FontSize: 1.5rem;
    --h2FontSize: 1.25rem;
    --h3FontSize: 1rem;
    --normalFontsize: .948rem;
    --smalllFontsize: .813rem;
    --smallestFontsize: .75rem;
  
  
}

.listing-section {
    flex-basis: 70%;
    
    .heading {
       width: 100%;
       justify-content: space-between;

       h1 {
        font-size: var(--h2FontSize);
        color: var(--blackColor);
        font-weight: 700;
       }

       .btn {
        border: 1px solid transparent;
       }    
       .btn:hover {
        border: 1px solid var(--primaryColor);
        background: var(--paleGreen);
        
    }    
       

    }

    .sec-container {
        width: 100%;
        margin: 1rem 0;
  
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: space-between;

        .single-item {
            
            position: relative;
            width: 150px;
            height: 170px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            border-radius: 10px;
            background: var(--itemcardcolor);
            

            &:hover {
                background: var(--itemcardhover);
                box-shadow: 0 2px 2px var(--inputColor);
                }

                img {
                    max-width: 100px;
                }
                .icon {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    color: var(--primaryColor);
                }
                h1{
                    font-size: var(--h3FontSize);
                    color: var(--blackColor);
                    padding: 1rem 0;
                }

        }

    }

    .sellers{
       width: 100%;
       justify-content: space-between;
       gap: 1rem;
       align-items: flex-start;

       .top-sellers, .features-sellers {
            .heading {
                justify-content: space-between;
                padding: 3rem 0;

                .btn {
                    padding: .5rem 1rem;

                }
            }
            .card {
                padding: 1rem 1.5rem;
                border-radius: 1rem;
                background: var(--whiteGreen);
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                gap: .5rem;
                cursor: pointer;
                .users{
                    padding-right: 1rem;
                    border-right: 2px solid var(--greyText);
                    

                    img {
                        width: 35px;
                        height: 35px;
                        margin-left:5px;
                        border-radius: 50%;
                        object-fit: cover;
                        border: 2px solid var(--inputColor);
                        box-shadow: var(--BoxShadow);
                        transition: .5s ease;

                        &:hover {
                            transform: translateY(-5px);
                        }
                    }
                }
                
                .card-text {
                    span{
                        color: var(--blackColor);
                        font-weight: 500;
                        small{
                        font-weight: 400;
                        .date {
                            margin-left: 1rem;
                        }
                        }
                    }
                }
            }
        }
       
    }    
}