@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@500;700;900&family=Montserrat:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  transition: .3s ease;
}

img {
  width: 100%;
  height: 100%;
}
:root {
  --primaryColor: hsl(94, 59%, 35%);
  --HoverVolor: #bdf094;
  --paleGreen: hsl(96, 75%, 89%);
  --whiteGreen: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0, 18%);
  --textColor: hsl(240, 1%, 48%);
  --bgColor: hsl( 220, 10%, 94% );
  --greyText: rgba(190, 190, 190);
  --inputColor: hsl(330, 12%, 97); 

  /* font type */

  --biggesFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontsize: .948rem;
  --smalllFontsize: .813rem;
  --smallestFontsize: .75rem;


}

/* defaul style */

html {
  font-size: 90%;
}

.icon {
  font-size: var(--h2FontSize);
}
.flex {
  display: flex;
  align-items: center;
  


}

.grid {
  display: grid;
  align-items: center;
  gap: 1.5rem;

}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--inputColor);
  color: var(--textColor); 
  padding: .8rem 1rem;
}

.btn .icon {
  margin-left: .5rem;

}

.btn:hover {
  color: var(--primaryColor);
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 1rem;

}

.container {
  position: relative;
  width: 95vw;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bgColor);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

/* hide yhe scroll  thumb*/

::-webkit-scrollbar{
  display: none;
}